import axios from 'axios';

const postDataUrl =
  'https://prod-133.westeurope.logic.azure.com/workflows/5162f376e85d416e9f6eb29950c0ca97/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=cmcFSKW7Knb9ooXAIGGjRaJaXdW-RBMn7gM5OViTFl0';

export const postFormData = async (values) => {
  try {
    const response = await axios.post(postDataUrl, values);
    if (response.status === 202) {
      return response.data;
    }
    throw new Error('Server Error');
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error(error.message);
  }
};

const countUrl =
  'https://prod-08.westeurope.logic.azure.com:443/workflows/f58dbb80347b4cb9956c9d3028d9bfc4/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=8jWxDoBSVnsOHWd-JrSZW99Z1GrwWGJhB5sBheV29SA';

export const postCountData = async (values) => {
  try {
    const response = await axios.post(countUrl, values);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Server Error');
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error(error.message);
  }
};

const countForOneDayUrl =
  'https://prod-20.westeurope.logic.azure.com:443/workflows/7077fb120c9640f58aa62577914b60a1/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=XkOnztUlIY3Oy7_5fvh4o3O6O5dmwo0bBKOM5GX-uB8';

export const postCountDayData = async (values) => {
  try {
    const response = await axios.post(countForOneDayUrl, values);
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Server Error');
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error(error.message);
  }
};

const cancelVisitUrl =
  'https://prod-141.westeurope.logic.azure.com:443/workflows/2162cd2714d84aaebc83c99fc791c6b9/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=5r9ruSGHyhMWcxe5Hloqa5vqmuJ5JRQ8WTXvOoQcaF0';

export const cancelVisit = async (values) => {
  try {
    const response = await axios.post(cancelVisitUrl, values);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error(error.message);
  }
};
