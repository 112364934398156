import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DatePickerCalendar } from 'react-nice-dates';
import { enGB } from 'date-fns/locale';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { cancelVisitAction } from '../../features/DetailForm/FormAction';
import { Link } from 'react-router-dom';

const CancelVisit = props => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [date, setDate] = useState();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
  });

  const onSubmit = data => {
    dispatch(cancelVisitAction(data))
      .then(() => props.history.push('/'))
      .catch(err => setError('Something went wrong, please try again'));
  };

  return (
    <div>
      <div style={{ marginTop: '4rem' }}>
        <Link to="/register" className="btn-bilot">
          Go back
        </Link>
      </div>
      <form
        style={{ marginTop: '4rem' }}
        className="detailForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="formRow">
          {error}
          <div className="left">
            <label htmlFor="email">
              Email:<span className="indicator">*</span>
            </label>
          </div>
          <div className="right">
            <input
              name="email"
              type="email"
              className={errors.email && 'fieldError'}
              ref={register({
                required: 'Required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'invalid email address',
                },
              })}
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </div>
        </div>
        <div className="formRow">
          <div className="left">
            <label htmlFor="office">
              Bilot office location visited:<span className="indicator">*</span>
            </label>
          </div>
          <div className="right">
            <select name="office" ref={register}>
              <option value="Fi-Helsinki">Fi-Ruoholahti</option>
              <option value="Fi-Säterinportti">Fi-Säterinportti</option>
              <option value="Fi-Jyväskylä">Fi-Jyväskylä</option>
              <option value="SE-Stockholm">SE-Stockholm</option>
              <option value="PL-Poznan">PL-Poznan</option>
              <option value="PL-Warsaw">PL-Warsaw</option>
            </select>
          </div>
        </div>
        <div className="formRow">
          <div className="left">
            <label htmlFor="date">
              Date to cancel visiting Bilot office:
              <span className="indicator">*</span>
            </label>
          </div>
          <div className="right">
            <div className="calenderWpr">
              <DatePickerCalendar
                date={date}
                onDateChange={setDate}
                locale={enGB}
              />
            </div>
            <input
              name="date"
              type="text"
              className={errors.date && 'fieldError'}
              ref={register()}
              value={moment(date).format('DD.MM.YYYY')}
              style={{ display: 'none' }}
              readOnly
            />
          </div>
        </div>
        <button type="submit" className="btn-bilot">
          Submit
        </button>
      </form>
    </div>
  );
};

export default withRouter(CancelVisit);
