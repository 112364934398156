import {
  SEND_FORM_DATA_START,
  SEND_FORM_DATA_SUCCESS,
  SEND_FORM_DATA_FAILED,
  SEND_COUNT_DATA_START,
  SEND_COUNT_DATA_SUCCESS,
  SEND_COUNT_DATA_FAILED,
  SEND_COUNT_DATA_DAY_START,
  SEND_COUNT_DATA_DAY_SUCCESS,
  SEND_COUNT_DATA_DAY_FAILED,
  SET_OFFICE_SUCCESS,
} from './FormAction';

const initialState = {
  showLoading: false,
  formData: [],
  countVisitorsPerDay: [],
  countVisitorsForDay: null,
  office: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_FORM_DATA_START:
      return {
        ...state,
        showLoading: true,
      };
    case SEND_FORM_DATA_SUCCESS:
      return {
        ...state,
        formData: action.formData,
        showLoading: false,
      };
    case SEND_FORM_DATA_FAILED:
      return {
        ...state,
        showLoading: false,
      };
    case SEND_COUNT_DATA_START:
      return {
        ...state,
        showLoading: true,
      };
    case SEND_COUNT_DATA_SUCCESS:
      return {
        ...state,
        countVisitorsPerDay: action.payload,
      };
    case SEND_COUNT_DATA_FAILED:
      return {
        ...state,
        showLoading: false,
      };
    case SEND_COUNT_DATA_DAY_START:
      return {
        ...state,
        showLoading: true,
      };
    case SEND_COUNT_DATA_DAY_SUCCESS:
      return {
        ...state,
        countVisitorsForDay: action.payload,
      };
    case SEND_COUNT_DATA_DAY_FAILED:
      return {
        ...state,
        showLoading: false,
      };
    case SET_OFFICE_SUCCESS:
      return {
        ...state,
        office: action.payload,
      };
    default:
      return state;
  }
};
