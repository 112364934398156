import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DetailForm from '../../features/DetailForm/containers';
import { Link } from 'react-router-dom';

function SearchView() {
  const countVisitor = useSelector(
    (state) => state.SearchReducer.countVisitorsForDay
  );
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Bilot office visitor registration`;
  }, []);

  return (
    <React.Fragment>
      <h1>Bilot office visitor registration</h1>
      <div style={{ marginBottom: '20px' }}>
        <Link to="/cancel-visit" className="btn-bilot">
          Cancel Visit
        </Link>
        <p>
          Visitors at the office: <strong>{countVisitor}</strong>
        </p>
      </div>
      <DetailForm />
    </React.Fragment>
  );
}
export default SearchView;
