import React, { useEffect } from "react";
import FormSent from "../../features/FormSent";

export default function FormSentView() {
  // react hooks. Same as component did mount
  useEffect(() => {
    document.title = `Bilot - Form successfully sent`;
  }, []);
  return (
    <div className="registeredWpr">
      <p>Form successfully sent</p>
      <div>
        <FormSent />
      </div>
    </div>
  );
}
