import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { isSameDay } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { Calendar } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import * as moment from 'moment';
import {
  sendCountAction,
  sendCountDayAction,
  officeAction,
} from '../FormAction';
import { officeCapacity } from '../../common/appConfig/officeCapacity';

export default function DetailForm(props) {
  let history = useHistory();
  const { register, watch, handleSubmit, errors } = useForm({
    mode: 'onChange',
    defaultValues: {
      office: 'Fi-Helsinki',
      inputDate: '',
    },
  });

  const office = watch('office');
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedSingleDate, setSelectedSingleDate] = useState(
    moment(new Date()).format('DD.MM.YYYY')
  );

  const countVisitor = useSelector(
    state => state.SearchReducer.countVisitorsPerDay
  );
  const isOfficeFull = countVisitor
    .filter(item => item.vcount >= officeCapacity[office])
    .map(item => new Date(item.pvm));

  const modifiers = {
    selected: date =>
      selectedDates.some(selectedDate => isSameDay(selectedDate, date)),
    disabled: date =>
      isOfficeFull.some(selectedDate => isSameDay(selectedDate, date)),
  };

  const handleDayClick = date => {
    setSelectedSingleDate(moment(date).format('DD.MM.YYYY'));
    const exists = selectedDates.some(d => +d === +date);
    if (exists) {
      const indexToRemove = selectedDates.map(d => +d).indexOf(+date);
      const newSelectedDates = [...selectedDates];
      newSelectedDates.splice(indexToRemove, 1);
      return setSelectedDates(newSelectedDates);
    }
    setSelectedDates([...selectedDates, date]);
  };

  const datesToSend = selectedDates.map(d => moment(d).format('DD.MM.YYYY'));

  const today = new Date();
  const endDate = today.setDate(today.getDate() + 60);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(officeAction(office));

    dispatch(
      sendCountAction({
        office: office,
        start: moment(new Date()).format('YYYY-MM-DD'),
        end: moment(endDate).format('YYYY-MM-DD'),
      })
    );
  }, [office, dispatch]);

  useEffect(() => {
    dispatch(
      sendCountDayAction({
        Office: office,
        Date: selectedSingleDate,
      })
    );
  }, [selectedSingleDate, office]);

  const onSubmit = data => {
    const dataToSend = {
      name: data.name,
      company: data.company,
      email: data.email,
      hostName: data.hostName,
      office: data.office,
      phoneNumber: data.phoneNumber,
      relevantInformation: data.relevantInformation,
      dates: datesToSend,
    };
    props.SearchDataAction(dataToSend, history);
  };

  return (
    <form className="detailForm" onSubmit={handleSubmit(onSubmit)}>
      <div className="formRow">
        <div className="left">
          <label htmlFor="name">
            Name:<span className="indicator">*</span>
          </label>
        </div>
        <div className="right">
          <input
            name="name"
            type="text"
            className={errors.name && 'fieldError'}
            ref={register({
              required: 'Required',
              minLength: 1,
            })}
          />
          {errors.name && <p className="error">{errors.name.message}</p>}
        </div>
      </div>
      <div className="formRow">
        <div className="left">
          <label htmlFor="company">Company:</label>
        </div>
        <div className="right">
          <input name="company" type="text" ref={register()} />
          {errors.company && <p className="error">{errors.company.message}</p>}
        </div>
      </div>
      <div className="formRow">
        <div className="left">
          <label htmlFor="phoneNumber">
            Phone number:<span className="indicator">*</span>
          </label>
        </div>
        <div className="right">
          <input
            name="phoneNumber"
            type="text"
            className={errors.phoneNumber && 'fieldError'}
            ref={register({
              required: 'Required',
              minLength: 1,
            })}
          />
          {errors.phoneNumber && (
            <p className="error">{errors.phoneNumber.message}</p>
          )}
          <p className="detail">Please add country code</p>
        </div>
      </div>
      <div className="formRow">
        <div className="left">
          <label htmlFor="email">
            Email:<span className="indicator">*</span>
          </label>
        </div>
        <div className="right">
          <input
            name="email"
            type="email"
            className={errors.email && 'fieldError'}
            ref={register({
              required: 'Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'invalid email address',
              },
            })}
          />
          {errors.email && <p className="error">{errors.email.message}</p>}
        </div>
      </div>
      <div className="formRow">
        <div className="left">
          <label htmlFor="office">
            Bilot office location visited:<span className="indicator">*</span>
          </label>
        </div>
        <div className="right">
          <select name="office" ref={register}>
            <option value="Fi-Helsinki">Fi-Ruoholahti</option>
            <option value="Fi-Säterinportti">Fi-Säterinportti</option>
            <option value="Fi-Jyväskylä">Fi-Jyväskylä</option>
            <option value="SE-Stockholm">SE-Stockholm</option>
            <option value="PL-Poznan">PL-Poznan</option>
            <option value="PL-Warsaw">PL-Warsaw</option>
          </select>
        </div>
      </div>
      <div className="formRow">
        <div className="left">
          <label htmlFor="date">
            Date when visiting Bilot office:
            <span className="indicator">*</span>
          </label>
        </div>
        <div className="right">
          <div className="calenderWpr">
            <Calendar
              onDayClick={handleDayClick}
              modifiers={modifiers}
              locale={enGB}
              minimumDate={new Date()}
              maximumDate={new Date(endDate)}
            />
            {errors.inputDate && (
              <p className="error">{errors.inputDate.message}</p>
            )}
          </div>
          <input
            name="inputDate"
            type="text"
            className={errors.date && 'fieldError'}
            ref={register({
              required: 'Date is Required',
            })}
            value={selectedDates[0]}
            style={{ display: 'none' }}
            readOnly
          />
        </div>
      </div>
      <div className="formRow">
        <div className="left">
          <label htmlFor="hostName">Bilot host name:</label>
        </div>
        <div className="right">
          <input name="hostName" type="text" ref={register()} />
          {errors.hostName && (
            <p className="error">{errors.hostName.message}</p>
          )}
        </div>
      </div>
      <div className="formRow">
        <div className="left">
          <label htmlFor="relevantInformation">
            Food allergies and other relevant information:
          </label>
        </div>
        <div className="right">
          <textarea name="relevantInformation" ref={register()} />
        </div>
      </div>

      <button type="submit" className="submit">
        Submit
      </button>
    </form>
  );
}
