import React from "react";
import { Link } from "react-router-dom";

export default function Registered() {
  return (
    <div className="registered">
      <h1>Thank you for registering, welcome to Bilot</h1>
      <Link className="registerLink" to="/register">
        Register new person
      </Link>
    </div>
  );
}
