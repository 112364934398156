import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import './styles/main.scss';

import Header from './features/Header';
import Footer from './features/Footer';

import DetailForm from './views/DetailForm';
import FormSent from './views/FormSent';
import CancelVisit from './views/CancelVisit/CancelVisit';

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <main>
          <Switch>
            <Route path="/register">
              <DetailForm />
            </Route>
            <Route path="/form-sent">
              <FormSent />
            </Route>
            <Route path="/cancel-visit">
              <CancelVisit />
            </Route>
            <Redirect exact from="/" to="/register" />
          </Switch>
        </main>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
