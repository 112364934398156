import React from "react";

function Header() {
  return (
    <header className="appHeader">
      <svg
        version="1.0"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 637 202.3"
      >
        <g>
          <path
            d="M122.7,167.8c-11.2,25.7-36.1,34.4-62.3,34.4H0V0h52.5c25.7,0,49.2,8.7,59,33.9c2.7,7.1,4.1,14.8,4.1,22.1
		c0,16.7-5.7,27.6-18,38.3c19.1,10.9,30.1,26.5,30.1,49.2C127.6,151.9,126,160.1,122.7,167.8z M51.9,27.1H29.5v57.1h24
		c19.1,0,32.5-8.2,32.5-28.4C86.1,34.2,70.8,27.1,51.9,27.1z M91,123.5c-8.5-11.2-19.9-12.3-33.1-12.3H29.5v63.9h32
		c19.1,0,36.6-10.9,36.6-31.7C98.1,136.4,95.4,129,91,123.5z"
          ></path>
          <path d="M156.7,202.2V0h29.5v202.2H156.7z"></path>
          <path d="M227.5,202.2V0H257v175.2h71.6v27.1H227.5z"></path>
          <path
            className="st0"
            d="M424.9,202.2c-55.8,0-101.4-45.3-101.4-101.1C323.6,44.8,368.8,0,424.9,0c56.9,0,101.4,45,101.4,101.6
		C526.3,157.2,480.5,202.2,424.9,202.2z"
          ></path>
          <path d="M594.1,27.1v175.2h-29.5V27.1h-43.9V0H637v27.1H594.1z"></path>
        </g>
      </svg>
    </header>
  );
}
export default Header;
