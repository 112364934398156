import {
  postFormData,
  postCountData,
  postCountDayData,
  cancelVisit,
} from '../../Api'; // imports api calls

export const SEND_FORM_DATA_START = 'SEND_FORM_DATA_START';
export const SEND_FORM_DATA_SUCCESS = 'SEND_FORM_DATA_SUCCESS';
export const SEND_FORM_DATA_FAILED = 'SEND_FORM_DATA_FAILED';

export const SEND_COUNT_DATA_START = 'SEND_COUNT_DATA_START';
export const SEND_COUNT_DATA_SUCCESS = 'SEND_COUNT_DATA_SUCCESS';
export const SEND_COUNT_DATA_FAILED = 'SEND_COUNT_DATA_FAILED';

export const SEND_COUNT_DATA_DAY_START = 'SEND_COUNT_DATA_DAY_START';
export const SEND_COUNT_DATA_DAY_SUCCESS = 'SEND_COUNT_DATA_DAY_SUCCESS';
export const SEND_COUNT_DATA_DAY_FAILED = 'SEND_COUNT_DATA_DAY_FAILED';

export const SET_OFFICE_SUCCESS = 'SET_OFFICE_SUCCESS';

export const CANCEL_VISIT_START = 'CANCEL_VISIT_START';
export const CANCEL_VISIT_SUCCESS = 'CANCEL_VISIT_SUCCESS';
export const CANCEL_VISIT_FAIL = 'CANCEL_VISIT_FAIL';

export const SearchDataAction = (val, history) => async (dispatch) => {
  dispatch({
    type: SEND_FORM_DATA_START,
  });
  try {
    // gets data from server
    let formData = await postFormData(val);
    // filters out which font start with wanted string
    dispatch({ type: SEND_FORM_DATA_SUCCESS, formData });
    history.push('/form-sent');
  } catch (error) {
    // Catches error
    alert('Error saving data');
    console.log('error', error);
    return dispatch({ type: SEND_FORM_DATA_FAILED, payload: error });
  }
};

export const sendCountAction = (data) => async (dispatch) => {
  dispatch({
    type: SEND_COUNT_DATA_START,
  });
  try {
    const response = await postCountData(data);
    dispatch({
      type: SEND_COUNT_DATA_SUCCESS,
      payload: response.Table1,
    });
  } catch (error) {
    dispatch({
      type: SEND_COUNT_DATA_FAILED,
    });
  }
};

export const sendCountDayAction = (data) => async (dispatch) => {
  dispatch({
    type: SEND_COUNT_DATA_DAY_START,
  });
  try {
    const response = await postCountDayData(data);
    dispatch({
      type: SEND_COUNT_DATA_DAY_SUCCESS,
      payload: response.ResultSets.Table1[0].VisitorCount,
    });
  } catch (error) {
    dispatch({
      type: SEND_COUNT_DATA_DAY_FAILED,
    });
  }
};

export const officeAction = (office) => async (dispatch) => {
  dispatch({
    type: SET_OFFICE_SUCCESS,
    payload: office,
  });
};

export const cancelVisitAction = (data) => async (dispatch) => {
  dispatch({
    type: CANCEL_VISIT_START,
  });
  try {
    await cancelVisit(data);
    dispatch({
      type: CANCEL_VISIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CANCEL_VISIT_FAIL,
    });
  }
};
