import { connect } from "react-redux";
import Registration from "../components";
import { SearchDataAction } from "../FormAction";

// gets data from Store
const mapStateToProps = state => ({
  searchData: state.SearchReducer.searchData,
  loadingData: state.SearchReducer.showLoading
});
// connects functions
export default connect(mapStateToProps, {
  SearchDataAction
})(Registration);
