import React from 'react';

const Footer = () => (
  <footer className="footer-container">
    <ul>
      <li>
        <a href="https://www.bilot.group">www.bilot.group</a>
      </li>
      <li>
        <a href="https://bilot.group/privacy-and-cookie-policy">
          Privacy and cookie policy
        </a>
      </li>
    </ul>
  </footer>
);

export default Footer;
